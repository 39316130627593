<script>
import LegoModalLayout from '@/views/Helexium/Lego/LegoModalLayout.vue'
import AddBtn from '@/components/buttons/AddBtn.vue';

export default {
  components: {
    AddBtn,
    LegoModalLayout
  },
  props: {
    boardId: {
      required: true,
    }
  },
  data() {
    return {
      collaborators: [],
      selectedColaborador: null,
      legoRoles: [],
      personas: [],
      loading: false
    }
  },
  computed: {
    board() {
      return this.$store.state.lego.board
    },
    posiblesColaboradores() {
      return this.personas.filter(persona => !this.collaborators.find(colaborador => colaborador.personaId == persona.personaId))
    },
    roleDefault() {
      return this.legoRoles.sort((a, b) => b.level - a.level)[0]
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level
    },
    evalAccess() {
      // cuando es global el template o de una empresa solo el admin puede agregar tendrá acceso
      const a = this.board.isGlobalTemplate
      const b = this.roleLevel == 1
      const c = !!this.board.empresaTemplateId
      return b || !a && !c
    }

  },
  async mounted() {
    await this.$store.dispatch('lego/getAndSetBoard', this.boardId)
    this.collaborators = JSON.parse(JSON.stringify(this.board.collaborators))
    this.personas = await this.$store.dispatch('getLegoPosiblesColaboradores', this.empresaId)
    this.legoRoles = await this.$store.dispatch('getLegoRoles')
  },
  methods: {
    filterRoles( legoRoles, colaborador ) {

      if( colaborador.role.level <= this.roleLevel ) {
        return legoRoles
      } else {
        return legoRoles.filter( role => this.roleLevel <= role.level  )
      }

    },

    addColaborador() {
      if (this.selectedColaborador == null) return
      let colaborador = this.posiblesColaboradores.find(colaborador => colaborador.personaId == this.selectedColaborador.personaId)
      colaborador.roleId = this.roleDefault.id
      colaborador.role = this.roleDefault
      this.collaborators.push(colaborador)
      this.selectedColaborador = null
    },
    deleteColaborador(colaborador, index) {
      if (colaborador.role.level <= this.roleLevel ) return
      this.collaborators.splice(index, 1)
    },
    async updateColaborador() {

      this.collaborators.forEach(collaborator => {
        collaborator.legoBoardId = this.board.id
        return collaborator
      })

      this.loading = true
      await this.$store.dispatch('lego/updateCollaboratorsInBoard',
        {
          boardId: this.board.id,
          collaborators: this.collaborators
        })
      this.loading = false
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      return value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }
  }
}
</script>

<template>
  <LegoModalLayout title="Colaboradores">
    <div class="wrapper-collaborators">
  
      <div v-if="evalAccess">
        <form class="body" @submit.prevent="addColaborador">
          <div>
            <label for="Colaboradores ">Personas</label>
            <div class="d-flex" v-if="roleLevel < 3">
              <v-select class="lego w-100" placeholder="Selecciona un colaborador" v-model="selectedColaborador"
                :get-option-label="(item) => item.name" :options="posiblesColaboradores">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!selectedColaborador" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <vs-button   class="m-0 button-add" type="submit">Agregar</vs-button>
            </div>
          </div>
  
          <div class="wrapper-colaboradores">
            <div class="colaborador" v-for="(colaborador, index) in collaborators" :key="colaborador.personaId">
              <span>
                {{ colaborador.name | capitalize }}
              </span>
              <div class="controls-in-collaborator" v-if="roleLevel < 3">
                <v-select class="lego" :disabled="roleLevel >= colaborador.role.level"
                  v-model="collaborators[index].roleId" :reduce="item => item.id" :get-option-label="(item) => item.name"
                  :options="filterRoles(legoRoles, colaborador)"></v-select>
                <span>
                  <AddBtn :disabled="roleLevel >= colaborador.role.level" class="close lego-danger"
                    @click="deleteColaborador(colaborador, index)" />
                </span>
              </div>
              <div v-else>
                <span>{{ colaborador.role.name }}</span>
              </div>
            </div>
          </div>
        </form>
        <footer class="d-flex justify-content-end align-content-center" v-if="roleLevel < 3">
          <vs-button :loading="loading" success   @click="updateColaborador">
            Actualizar
          </vs-button>
        </footer>
      </div>
      <div v-else>
        <div v-if="!!board.empresaTemplateId">
          Esta funcionalidad no está disponible para tableros de plantillas de empresa.
        </div>
        <div v-else-if="board.isGlobalTemplate">
          Esta funcionalidad no está disponible para plantillas globales.
        </div>
        <div v-else>
          Solo el administrador puede agregar colaboradores.
        </div>
      </div>

    </div>

  </LegoModalLayout>
</template>

<style scoped>
.header {
  margin-bottom: 1.5rem;

}

.task_tittle {
  background-color: transparent;
  font-size: 1.5rem;
  color: white;
  border: none;
  margin-bottom: 0.5rem;
  transition: .3s;
  box-shadow: 0 0px 0 0 rgba(255, 255, 255, 0.5);
  width: 100%;
}

.task_tittle:focus {
  outline: none;
  box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.5);
}


.wrapper-collaborators {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper-colaboradores {
  margin: .5rem;
}

.body {
  flex: 1;
  min-height: 100%;
}

.button-add {
  border-radius: 0 5px 5px 0;
}

.colaborador {
  padding: .5rem;
  background-color: var(--lego-oscuro);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .5rem 0;
}

.controls-in-collaborator {
  display: flex;
  gap: .5rem;
  align-items: center;
  width: 200px;
}

.controls-in-collaborator .lego {
  width: 100%;
}
</style>